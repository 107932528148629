<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            paysafecard classic<br /><br />
            paysafecard {{ value }}<br />

            <p>PIN = RAHAA: ÄLÄ ANNA KOODIA PUHELIMITSE</p>

            Tuotteen arvo {{ value }} <br />
            Sarjanumero: {{ serial }}<br />
            PIN-KOODI: {{ code }}<br /><br />
            Jäljityskoodi: {{ traceCode }} <br />
            Terminaalin tunnus: {{ terminalId }} <br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            <p>
                <strong>Käytthöohje:</strong>
            </p>
            <ul>
                <li>Valitse verkkokauppa ja haluamasi tuote</li>
                <li>Valitse mksutavaksi paysafecard</li>
                <li>Näppäile PIN-tunnus ja ostoksesi on maksettu</li>
            </ul>
            <p>
                Tukipalvelu :<br />
                <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a><br /><br />
            </p>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component :is="textComponent" font-family="'Arial', 'Helvetica', 'sans-serif'" line-height="18px" font-size="13px" color="#4A4A4A">
            <p>
                Käyttöehdot :<br />
                Koodin jälleenmyynti on kielletty !<br />
                Tarkista ylei set käyttöehdot osoitteesta <a target="_blank" href="https://www.paysafecard.com">www.paysafecard.com</a><br />
                Maksutavan myy <strong>Paysafe Prepaid Services Ltd</strong>.
            </p>
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
